<template>
  <div class="page-basic-layout">
    <Header></Header>
    <div class="page-basic-body">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./LayoutFooter.vue";
import Header from "./LayoutHeader.vue";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page-basic-layout {
  min-height: 100vh;
  min-width: 1080px;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}
.page-basic-body {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 680px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 50px;
}
</style>
